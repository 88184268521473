var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ScheduleDetailsStyled', [_c('div', {
    staticClass: "details"
  }, [_c('div', [_c('strong', [_vm._v("Name: ")]), _vm._v(_vm._s(_vm.schedule.name))]), _c('div', [_c('strong', [_vm._v("Calendar: ")]), _vm._v(_vm._s(_vm.schedule.calendar.name))])]), _c('div', {
    staticClass: "recurrences-now"
  }, _vm._l(_vm.schedule.recurrences, function (recurrence, rIdx) {
    return _c('div', {
      key: "recurrence_".concat(rIdx)
    }, [_vm._l(recurrence, function (value, key) {
      return [_c('div', {
        key: "".concat(rIdx, "_").concat(key),
        staticClass: "kv"
      }, [_vm._v(_vm._s(key) + " - " + _vm._s(value))])];
    })], 2);
  }), 0), _c('div', {
    staticClass: "recurrences-adjusted"
  }, _vm._l(_vm.adjustedSchedule.recurrences, function (recurrence, rIdx) {
    return _c('div', {
      key: "recurrence_".concat(rIdx)
    }, [_vm._l(recurrence, function (value, key) {
      return [_c('div', {
        key: "".concat(rIdx, "_").concat(key),
        staticClass: "kv"
      }, [_vm._v(_vm._s(key) + " - " + _vm._s(value))])];
    })], 2);
  }), 0), _c('div', {
    staticClass: "timezones"
  }, [_c('Multiselect', {
    attrs: {
      "options": _vm.timezonesAvailable,
      "hideSelected": true,
      "allowEmpty": false,
      "showLabels": false,
      "placeholder": "Timezone: ".concat(_vm.timezoneSelected)
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function fn(_ref) {
        var option = _ref.option;
        return [_c('span', [_vm._v("Timezone: ")]), _vm._v(" " + _vm._s(option) + " ")];
      }
    }]),
    model: {
      value: _vm.timezoneSelected,
      callback: function callback($$v) {
        _vm.timezoneSelected = $$v;
      },
      expression: "timezoneSelected"
    }
  }), _c('Multiselect', {
    attrs: {
      "options": _vm.timezonesAvailable,
      "hideSelected": true,
      "allowEmpty": false,
      "showLabels": false,
      "placeholder": "Source timezone: ".concat(_vm.timezoneSelected)
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function fn(_ref2) {
        var option = _ref2.option;
        return [_c('span', [_vm._v("Source timezone: ")]), _vm._v(" " + _vm._s(option) + " ")];
      }
    }]),
    model: {
      value: _vm.importSourceTimezoneSelected,
      callback: function callback($$v) {
        _vm.importSourceTimezoneSelected = $$v;
      },
      expression: "importSourceTimezoneSelected"
    }
  }), _c('Multiselect', {
    attrs: {
      "options": ['v1', 'v2'],
      "hideSelected": true,
      "allowEmpty": false,
      "showLabels": false,
      "placeholder": "Source timezone: ".concat(_vm.timezoneSelected)
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function fn(_ref3) {
        var option = _ref3.option;
        return [_c('span', [_vm._v("Implementation: ")]), _vm._v(" " + _vm._s(option) + " ")];
      }
    }]),
    model: {
      value: _vm.implementation,
      callback: function callback($$v) {
        _vm.implementation = $$v;
      },
      expression: "implementation"
    }
  }), _c('button', {
    attrs: {
      "disabled": !_vm.isDirty
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("save")])], 1), _c('div', {
    staticClass: "occurrences"
  }, [_c('div', [_c('strong', [_vm._v("Current:")])]), _vm._l(_vm.occurrences, function (occurrence, i) {
    return _c('div', {
      key: i,
      staticClass: "occurrence"
    }, [_c('div', [_c('strong', [_vm._v("@Local:")]), _vm._v(" " + _vm._s(occurrence.date.setZone('local').toISO()))]), _c('div', [_c('strong', [_vm._v("@UTC:")]), _vm._v(" " + _vm._s(occurrence.date.setZone('UTC').toISO()))]), _c('div', [_c('strong', [_vm._v("@" + _vm._s(_vm.schedule.timezone) + ":")]), _vm._v(" " + _vm._s(occurrence.date.setZone(_vm.schedule.timezone).toISO()) + " ")])]);
  })], 2), _c('div', {
    staticClass: "occurrences"
  }, [_vm.isDirty ? [_c('div', [_c('strong', [_vm._v("After changes saved:")])]), _vm._l(_vm.occurrencesV2, function (occurrence, i) {
    return _c('div', {
      key: i,
      staticClass: "occurrence"
    }, [_c('div', [_c('strong', [_vm._v("@Local:")]), _vm._v(" " + _vm._s(occurrence.date.setZone('local').toISO()))]), _c('div', [_c('strong', [_vm._v("@UTC:")]), _vm._v(" " + _vm._s(occurrence.date.setZone('UTC').toISO()))]), _c('div', [_c('strong', [_vm._v("@" + _vm._s(_vm.timezoneSelected) + ":")]), _vm._v(" " + _vm._s(occurrence.date.setZone(_vm.timezoneSelected).toISO()) + " ")])]);
  })] : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }