<template>
  <ScheduleDetailsStyled>
    <div class="details">
      <div><strong>Name: </strong>{{ schedule.name }}</div>
      <div><strong>Calendar: </strong>{{ schedule.calendar.name }}</div>
    </div>
    <div class="recurrences-now">
      <div v-for="(recurrence, rIdx) in schedule.recurrences" :key="`recurrence_${rIdx}`">
        <template v-for="(value, key) of recurrence">
          <div class="kv" :key="`${rIdx}_${key}`">{{ key }} - {{ value }}</div>
        </template>
      </div>
    </div>
    <div class="recurrences-adjusted">
      <div v-for="(recurrence, rIdx) in adjustedSchedule.recurrences" :key="`recurrence_${rIdx}`">
        <template v-for="(value, key) of recurrence">
          <div class="kv" :key="`${rIdx}_${key}`">{{ key }} - {{ value }}</div>
        </template>
      </div>
    </div>
    <div class="timezones">
      <Multiselect
        v-model="timezoneSelected"
        :options="timezonesAvailable"
        :hideSelected="true"
        :allowEmpty="false"
        :showLabels="false"
        :placeholder="`Timezone: ${timezoneSelected}`"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          <span>Timezone: </span>
          {{ option }}
        </template>
      </Multiselect>

      <Multiselect
        v-model="importSourceTimezoneSelected"
        :options="timezonesAvailable"
        :hideSelected="true"
        :allowEmpty="false"
        :showLabels="false"
        :placeholder="`Source timezone: ${timezoneSelected}`"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          <span>Source timezone: </span>
          {{ option }}
        </template>
      </Multiselect>

      <Multiselect
        v-model="implementation"
        :options="['v1', 'v2']"
        :hideSelected="true"
        :allowEmpty="false"
        :showLabels="false"
        :placeholder="`Source timezone: ${timezoneSelected}`"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          <span>Implementation: </span>
          {{ option }}
        </template>
      </Multiselect>
      <button :disabled="!isDirty" @click="save">save</button>
    </div>
    <div class="occurrences">
      <div><strong>Current:</strong></div>
      <div class="occurrence" v-for="(occurrence, i) in occurrences" :key="i">
        <div><strong>@Local:</strong> {{ occurrence.date.setZone('local').toISO() }}</div>
        <div><strong>@UTC:</strong> {{ occurrence.date.setZone('UTC').toISO() }}</div>
        <div>
          <strong>@{{ schedule.timezone }}:</strong> {{ occurrence.date.setZone(schedule.timezone).toISO() }}
        </div>
      </div>
    </div>
    <div class="occurrences">
      <template v-if="isDirty">
        <div><strong>After changes saved:</strong></div>
        <div class="occurrence" v-for="(occurrence, i) in occurrencesV2" :key="i">
          <div><strong>@Local:</strong> {{ occurrence.date.setZone('local').toISO() }}</div>
          <div><strong>@UTC:</strong> {{ occurrence.date.setZone('UTC').toISO() }}</div>
          <div>
            <strong>@{{ timezoneSelected }}:</strong> {{ occurrence.date.setZone(timezoneSelected).toISO() }}
          </div>
        </div>
      </template>
    </div>
  </ScheduleDetailsStyled>
</template>

<script>
import chroma from 'chroma-js'
import Multiselect from 'vue-multiselect'
import { cloneDeep } from 'lodash'
import { styled } from '@egoist/vue-emotion'
import { DateTime, Interval } from 'luxon'
import { getTimeZones } from '@vvo/tzdb'

import { getScheduleOccurencesInInterval } from '@common/utils'
import { flexCenter, buttonReset } from '@styles/mixins'

import UPDATE_SCHEDULE_MUTATION from '#/graphql/calendar/schedule/update.gql'

const ScheduleDetailsStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  .details,
  .recurrences-now,
  .recurrences-adjusted {
    padding: 0.5rem;
  }
  .details {
    grid-column: span 2;
  }
  .timezones {
    grid-column: span 2;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }
  .multiselect {
    max-width: 18rem;
  }
  .occurrences {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-gap: 0.5rem;
    border-radius: 1rem;
    overflow: hidden;
    > div {
      padding: 0.5rem;
      background: ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
    }
  }
  button {
    ${buttonReset}
    ${flexCenter}
    margin-right: .5rem;
    padding: 0.2rem;
    border-radius: 0.5rem;
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
    &:hover {
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(1).css()};
    }
    &:disabled {
      background: ${p => chroma(p.theme.colors.white).alpha(0.5).css()};
    }
  }
`

const TIMEZONES = getTimeZones({
  includeUtc: true,
})

export default {
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
  components: {
    ScheduleDetailsStyled,
    Multiselect,
  },
  data() {
    return {
      timezonesAvailable: TIMEZONES.map(m => m.name).sort((a, b) => a.localeCompare(b)),
      timezoneSelected: null,
      importSourceTimezoneSelected: null,
      implementation: 'v1',
    }
  },
  computed: {
    isDirty() {
      return (
        this.timezoneSelected !== this.schedule.timezone ||
        this.importSourceTimezoneSelected !== this.schedule.importSourceTimezone ||
        this.schedule.useTimezoneInRSchedule !== (this.implementation === 'v2')
      )
    },
    adjustedSchedule() {
      const clonedSchedule = cloneDeep(this.schedule)

      if (this.implementation === 'v2') {
        clonedSchedule.timezone = this.timezoneSelected
        clonedSchedule.useTimezoneInRSchedule = true
        clonedSchedule.recurrences = clonedSchedule.recurrences.map(recurrence => {
          const start = DateTime.fromISO(recurrence.start).setZone(this.importSourceTimezoneSelected)
          const hours = start.hour
          const minutes = start.minute
          const seconds = start.second
          return {
            ...recurrence,
            byHourOfDay: [hours],
            byMinuteOfHour: [minutes],
            bySecondOfMinute: [seconds],
          }
        })
      } else {
        clonedSchedule.timezone = this.timezoneSelected
        clonedSchedule.useTimezoneInRSchedule = false
        clonedSchedule.recurrences = clonedSchedule.recurrences.map(recurrence => {
          return {
            ...recurrence,
            byHourOfDay: [],
            byMinuteOfHour: [],
            bySecondOfMinute: [],
          }
        })
      }
      return clonedSchedule
    },
    occurrences() {
      const start = DateTime.now().startOf('month')
      const end = start.endOf('month')
      const currentMonthInterval = Interval.fromDateTimes(start, end)
      // const timezone = this.timezoneSelected
      return getScheduleOccurencesInInterval(this.schedule, currentMonthInterval)
    },
    occurrencesV2() {
      const start = DateTime.now().startOf('month')
      const end = start.endOf('month')
      const currentMonthInterval = Interval.fromDateTimes(start, end)
      return getScheduleOccurencesInInterval(this.adjustedSchedule, currentMonthInterval)
    },
  },
  watch: {
    schedule: {
      handler(schedule) {
        this.timezoneSelected = schedule.timezone
        this.importSourceTimezoneSelected = schedule.importSourceTimezone
        this.implementation = schedule.useTimezoneInRSchedule ? 'v2' : 'v1'
      },
      immediate: true,
    },
  },
  methods: {
    save() {
      if (!this.isDirty) {
        return
      }
      if (this.schedule.recurrences.length > 1) {
        // console.error(`schedule has more than 1 recurrence, updating not supported`)
        return
      }
      const firstRecurrence = this.schedule.recurrences[0]
      const firstRecurrenceId = this.schedule.recurrences[0]?.id
      const isNewImplementation = this.implementation === 'v2'
      const start = DateTime.fromISO(firstRecurrence.start).setZone(this.importSourceTimezoneSelected)
      const hours = start.hour
      const minutes = start.minute
      const seconds = start.second

      const recurrenceData = isNewImplementation
        ? {
            byHourOfDay: {
              set: [hours],
            },
            byMinuteOfHour: {
              set: [minutes],
            },
            bySecondOfMinute: {
              set: [seconds],
            },
          }
        : {
            byHourOfDay: {
              set: [],
            },
            byMinuteOfHour: {
              set: [],
            },
            bySecondOfMinute: {
              set: [],
            },
          }

      const data = {
        timezone: {
          set: this.timezoneSelected,
        },
        importSourceTimezone: {
          set: this.importSourceTimezoneSelected,
        },
        useTimezoneInRSchedule: {
          set: isNewImplementation,
        },
        recurrences: {
          update: {
            data: recurrenceData,
            where: {
              id: firstRecurrenceId,
            },
          },
        },
      }

      this.$apollo.mutate({
        mutation: UPDATE_SCHEDULE_MUTATION,
        variables: {
          where: {
            id: this.schedule?.id,
          },
          data,
        },
      })
    },
  },
}
</script>
