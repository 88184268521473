var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EditScheduleStyled', [_c('ApolloQuery', {
    attrs: {
      "query": _vm.SCHEDULE_QUERY,
      "variables": _vm.variables
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _ref$result = _ref.result,
          loading = _ref$result.loading,
          error = _ref$result.error,
          data = _ref$result.data;
        return [loading ? _c('div', {
          staticClass: "loading"
        }, [_vm._v("Loading...")]) : error ? _c('div', {
          staticClass: "error"
        }, [_vm._v(_vm._s(error))]) : data && data.schedule ? [_c('ScheduleDetails', {
          attrs: {
            "schedule": data.schedule
          }
        })] : _c('div', {
          staticClass: "no-result"
        }, [_vm._v("Schedule " + _vm._s(_vm.$route.params.id) + " not found.")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }