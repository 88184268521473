<template>
  <EditScheduleStyled>
    <ApolloQuery :query="SCHEDULE_QUERY" :variables="variables">
      <template v-slot="{ result: { loading, error, data } }">
        <div v-if="loading" class="loading">Loading...</div>
        <div v-else-if="error" class="error">{{ error }}</div>
        <template v-else-if="data && data.schedule">
          <ScheduleDetails :schedule="data.schedule" />
        </template>
        <div v-else class="no-result">Schedule {{ $route.params.id }} not found.</div>
      </template>
    </ApolloQuery>
  </EditScheduleStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import ScheduleDetails from './Schedule/ScheduleDetails.vue'

import SCHEDULE_QUERY from '#/graphql/operations/calendars/scheduleQuery.graphql'

const EditScheduleStyled = styled('div')`
  display: grid;
  grid-gap: 1rem;
  padding: 1rem 1px;
  margin-top: 2rem;
  background: ${p => chroma(p.theme.colors.white).alpha(0.1).css()};
  backdrop-filter: blur(7px);
  border-radius: 0.5rem;

  > div {
    background: #141414;
    padding: 0.5rem;
  }
`

export default {
  components: {
    EditScheduleStyled,
    ScheduleDetails,
  },
  data() {
    return {
      schedule: null,
      SCHEDULE_QUERY,
    }
  },
  computed: {
    skip() {
      return !this.$route?.params?.id
    },
    variables() {
      return {
        where: {
          id: this.$route?.params?.id,
        },
      }
    },
  },
  // apollo: {
  //   schedule: {
  //     query: SCHEDULE_QUERY,
  //     skip() {
  //       return !this.$route?.params?.id
  //     },
  //   },
  // },
}
</script>
